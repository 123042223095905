"use client";
import { jsx, jsxs } from "react/jsx-runtime";
import { useComboBox } from "@react-aria/combobox";
import { Item } from "@react-stately/collections";
import { useComboBoxState } from "@react-stately/combobox";
import { useRef } from "react";
import { tv } from "../../utils/tailwind";
import { field } from "../base";
import { ListBox } from "./components/ListBox";
import { Popover } from "./components/Popover";
import { ResetButton } from "./components/ResetButton";
const comboBox = tv({
  extend: field,
  slots: {
    resetButton: [
      "absolute end-x1_75 z-10 size-x2",
      "rounded-x2",
      "opacity-50 transition-opacity",
      "hover:opacity-70"
    ]
  }
});
const ComboBox = ({
  id,
  label,
  placeholder,
  selected,
  value,
  defaultValue,
  name,
  options,
  prefix,
  postfix,
  emptyState = /* @__PURE__ */ jsx("div", { className: "flex min-h-x5 items-center px-x1_25", children: "Couldn't find any matching items" }),
  error,
  description,
  className,
  children,
  isDisabled = false,
  isLoading = false,
  autoFocus = false,
  onSearch,
  onChange,
  onBlur
}) => {
  const state = useComboBoxState({
    children,
    items: options,
    menuTrigger: "focus",
    selectedKey: selected,
    inputValue: value,
    defaultInputValue: defaultValue,
    onInputChange: onSearch,
    onSelectionChange: onChange,
    allowsEmptyCollection: true,
    shouldCloseOnBlur: false
  });
  const inputRef = useRef(null);
  const resetButtonRef = useRef(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);
  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      id,
      label,
      isDisabled,
      placeholder,
      inputRef,
      listBoxRef,
      popoverRef,
      name,
      autoFocus,
      description
    },
    state
  );
  const { isOpen, collection } = state;
  const isIdleState = (state.inputValue.length === 0 || isLoading) && collection.size === 0;
  const hasInputValue = state.inputValue.length !== 0;
  const hasError = error !== void 0 && error !== null && error !== "";
  const hasDescription = description !== void 0 && description !== "";
  const styles = comboBox({
    isDisabled,
    isLoading,
    hasError
  });
  return /* @__PURE__ */ jsxs("fieldset", { className: styles.fieldset({ className }), children: [
    label !== void 0 && /* @__PURE__ */ jsx("label", { ...labelProps, className: styles.label(), children: label }),
    /* @__PURE__ */ jsxs(
      "div",
      {
        "data-prefix": prefix !== void 0,
        "data-postfix": postfix !== void 0,
        className: styles.input(),
        children: [
          prefix !== void 0 && /* @__PURE__ */ jsx("span", { className: styles.inputPrefix(), children: prefix }),
          /* @__PURE__ */ jsx(
            "input",
            {
              ...inputProps,
              className: styles.inputEl(),
              ref: inputRef,
              type: "text",
              onBlur: (e) => {
                if (e.relatedTarget !== null && // Don't close combobox if we've clicked on the clear button.
                (e.relatedTarget === resetButtonRef?.current || // Also keep open if the interactable element is within the popover.
                popoverRef.current?.contains(e.relatedTarget))) {
                  inputRef?.current?.focus();
                  return;
                }
                onBlur?.(selected ?? null);
                state.close();
                state.setFocused(false);
              }
            }
          ),
          isLoading && /* @__PURE__ */ jsx("span", { className: styles.spinner() }),
          !isLoading && state.isFocused && hasInputValue && /* @__PURE__ */ jsx(
            ResetButton,
            {
              ref: resetButtonRef,
              className: styles.resetButton(),
              onClick: () => {
                state.setInputValue("");
                state.setSelectedKey(null);
              }
            }
          ),
          !isLoading && (!state.isFocused || !hasInputValue) && postfix !== void 0 && /* @__PURE__ */ jsx("span", { className: styles.inputPostfix(), children: postfix })
        ]
      }
    ),
    hasError && /* @__PURE__ */ jsx("span", { className: styles.error(), children: error }),
    hasDescription && !hasError && /* @__PURE__ */ jsx("span", { className: styles.description(), children: description }),
    isOpen && /* @__PURE__ */ jsx(Popover, { popoverRef, inputRef, state, children: !isIdleState ? /* @__PURE__ */ jsx(
      ListBox,
      {
        ...listBoxProps,
        listBoxRef,
        state,
        emptyState
      }
    ) : null })
  ] });
};
ComboBox.Option = Item;
export {
  ComboBox,
  comboBox
};
