import { jsx } from "react/jsx-runtime";
import { cloneElement, isValidElement } from "react";
import { useButton } from "@react-aria/button";
import { useMenuContext } from "./MenuContext";
import { Button } from "../../Button";
const isButtonElement = (element) => {
  return typeof element !== "string" && isValidElement(element) && (element.type === Button || element.type === "button" || element.type === "a");
};
const Trigger = ({ label }) => {
  const { triggerRef, triggerProps } = useMenuContext();
  const { buttonProps } = useButton(triggerProps, triggerRef);
  return isButtonElement(label) ? cloneElement(label, {
    ...buttonProps,
    ref: triggerRef
  }) : /* @__PURE__ */ jsx(
    Button,
    {
      ref: triggerRef,
      ...buttonProps,
      variant: "outline",
      children: label
    }
  );
};
export {
  Trigger
};
