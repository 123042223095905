import { jsx } from "react/jsx-runtime";
import { createContext, useContext, useRef } from "react";
import { useMenuTrigger } from "@react-aria/menu";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTrigger } from "@react-aria/overlays";
const MenuContext = createContext(void 0);
const MenuProvider = ({
  defaultOpen,
  onOpenChange,
  children
}) => {
  const triggerRef = useRef(null);
  const overlayState = useOverlayTriggerState({
    defaultOpen,
    onOpenChange
  });
  const { menuProps } = useMenuTrigger(
    {},
    { ...overlayState, focusStrategy: "first" },
    triggerRef
  );
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "menu" },
    overlayState,
    triggerRef
  );
  return /* @__PURE__ */ jsx(
    MenuContext.Provider,
    {
      value: {
        menuProps,
        triggerRef,
        overlayState,
        triggerProps,
        overlayProps
      },
      children
    }
  );
};
const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useMenuContext: Attempting to access undefined context, possibly outside of `MenuProvider`"
    );
  }
  return context;
};
export {
  MenuContext,
  MenuProvider,
  useMenuContext
};
