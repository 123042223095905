import { jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
const ResetButton = forwardRef(
  ({ className, onClick }, ref) => /* @__PURE__ */ jsx("button", { ref, className, onClick, tabIndex: -1, children: /* @__PURE__ */ jsx(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      xmlns: "http://www.w3.org/2000/svg",
      className: "absolute left-[-4px] top-[-4px] size-x3 fill-[--s-field-input-color-text]",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM8.46443 8.46455C8.85496 8.07403 9.48812 8.07403 9.87864 8.46455L12 10.5859L14.1214 8.46455C14.5119 8.07403 15.1451 8.07403 15.5356 8.46455C15.9261 8.85508 15.9261 9.48824 15.5356 9.87877L13.4142 12.0001L15.5355 14.1214C15.926 14.5119 15.926 15.1451 15.5355 15.5356C15.145 15.9261 14.5118 15.9261 14.1213 15.5356L12 13.4143L9.87874 15.5356C9.48821 15.9261 8.85505 15.9261 8.46452 15.5356C8.074 15.1451 8.074 14.5119 8.46452 14.1214L10.5858 12.0001L8.46443 9.87877C8.07391 9.48824 8.07391 8.85508 8.46443 8.46455Z"
        }
      )
    }
  ) })
);
ResetButton.displayName = "ResetButton";
export {
  ResetButton
};
