import { jsx, jsxs } from "react/jsx-runtime";
import { DismissButton, Overlay, usePopover } from "@react-aria/overlays";
import { useMount } from "../../../utils/useMount";
import { getCSSVar } from "../../../utils/css";
const Popover = ({ state, inputRef, popoverRef, children }) => {
  const { popoverProps } = usePopover(
    {
      triggerRef: inputRef,
      isNonModal: true,
      placement: "bottom start",
      offset: 8,
      crossOffset: -1,
      shouldCloseOnInteractOutside: () => {
        return false;
      },
      popoverRef
    },
    state
  );
  useMount(() => {
    if (inputRef.current !== null && popoverRef.current !== null) {
      const theme = getCSSVar("--s-theme", inputRef.current);
      popoverRef.current.dataset["theme"] = theme;
    }
  });
  return /* @__PURE__ */ jsx(Overlay, { children: /* @__PURE__ */ jsxs(
    "div",
    {
      ref: popoverRef,
      className: "z-popover",
      ...popoverProps,
      "data-theme": "light",
      children: [
        children,
        /* @__PURE__ */ jsx(DismissButton, { onDismiss: state.close })
      ]
    }
  ) });
};
export {
  Popover
};
