"use client";
import { jsx, jsxs } from "react/jsx-runtime";
import { Dialog } from "./components/Dialog";
import { Title } from "./components/Title";
import { cn } from "../utils/tailwind";
const Body = ({
  className,
  children
}) => {
  return /* @__PURE__ */ jsx(
    "div",
    {
      className: cn(
        "scrollbar relative flex h-full flex-col overflow-y-auto overflow-x-hidden px-x2 py-x1_5 text-xs lg:max-h-[600px] lg:px-x3",
        className
      ),
      children
    }
  );
};
const Actions = ({
  className,
  children
}) => {
  return /* @__PURE__ */ jsx(
    "div",
    {
      className: cn(
        "flex justify-end gap-x1 px-x2 py-x1 pb-x2 lg:px-x3",
        className
      ),
      children
    }
  );
};
const Modal = ({
  isOpen = false,
  children,
  className,
  title,
  isDismissable = true,
  onClose,
  "aria-label": ariaLabel
}) => {
  return /* @__PURE__ */ jsxs(
    Dialog,
    {
      className,
      isDismissable,
      isOpen,
      onClose,
      "aria-label": ariaLabel ?? (typeof title === "string" ? title : "Modal"),
      children: [
        /* @__PURE__ */ jsx(Title, { isDismissable, onClose, children: title }),
        children
      ]
    }
  );
};
Modal.Body = Body;
Modal.Actions = Actions;
export {
  Modal
};
