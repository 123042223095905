import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMenuSection } from "@react-aria/menu";
import { ListItem } from "./ListItem";
const ListSection = ({ section, state }) => {
  const { itemProps, headingProps, groupProps } = useMenuSection({
    heading: section.rendered,
    "aria-label": section.props?.["aria-label"]
  });
  if (section.hasChildNodes === false) {
    return section.key !== state.collection.getFirstKey() ? /* @__PURE__ */ jsx(
      "li",
      {
        className: "my-x1 border-0 border-t border-solid border-[--s-menu-popover-color-border]",
        role: "presentation"
      }
    ) : null;
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    section.key !== state.collection.getFirstKey() && /* @__PURE__ */ jsx(
      "li",
      {
        className: "my-x1 border-0 border-t border-solid border-[--s-menu-popover-color-border]",
        role: "presentation"
      }
    ),
    /* @__PURE__ */ jsxs("li", { ...itemProps, className: section.props?.className, children: [
      section.rendered && /* @__PURE__ */ jsx(
        "div",
        {
          ...headingProps,
          className: "flex items-center gap-x1 px-x2 py-x1 pt-0 text-sm",
          children: section.rendered
        }
      ),
      /* @__PURE__ */ jsx("ul", { className: "m-0 list-none p-0", ...groupProps, children: [...section.childNodes].map((node) => /* @__PURE__ */ jsx(ListItem, { item: node, state }, node.key)) })
    ] })
  ] });
};
export {
  ListSection
};
