import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Button } from "../../Button";
import { Heading } from "../../Heading";
const Title = ({ children, isDismissable, onClose }) => {
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);
  if (!children && !isDismissable)
    return null;
  return /* @__PURE__ */ jsxs("div", { className: "flex items-center justify-between px-x2 py-x1_5 pt-x2 lg:px-x3", children: [
    children && typeof children === "string" ? /* @__PURE__ */ jsx(Heading, { className: "text-base font-strong text-solid", children }) : children,
    isDismissable && /* @__PURE__ */ jsx(
      Button,
      {
        variant: "subtle",
        size: "medium",
        className: "size-x4 p-0",
        "aria-label": "Close",
        onClick: handleClose,
        children: /* @__PURE__ */ jsx(
          "svg",
          {
            className: "fill-solid",
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            xmlns: "http://www.w3.org/2000/svg",
            children: /* @__PURE__ */ jsx(
              "path",
              {
                fillRule: "evenodd",
                clipRule: "evenodd",
                d: "M7.75886 6.34273C7.36834 5.9522 6.73518 5.9522 6.34465 6.34273C5.95413 6.73325 5.95413 7.36642 6.34465 7.75694L10.5869 11.9992L6.34359 16.2426C5.95307 16.6331 5.95307 17.2663 6.34359 17.6568C6.73412 18.0473 7.36728 18.0473 7.75781 17.6568L12.0012 13.4134L16.2441 17.6564C16.6347 18.047 17.2678 18.047 17.6584 17.6564C18.0489 17.2659 18.0489 16.6327 17.6584 16.2422L13.4154 11.9992L17.6573 7.75731C18.0478 7.36678 18.0478 6.73362 17.6573 6.34309C17.2668 5.95257 16.6336 5.95257 16.2431 6.34309L12.0012 10.585L7.75886 6.34273Z"
              }
            )
          }
        )
      }
    )
  ] });
};
export {
  Title
};
