import { jsx, jsxs } from "react/jsx-runtime";
import { Overlay, useOverlayPosition } from "@react-aria/overlays";
import { useRef } from "react";
import { useMenuContext } from "./MenuContext";
import { mergeProps } from "@react-aria/utils";
import { DismissButton, useOverlay } from "@react-aria/overlays";
import { getCSSVar } from "../../utils/css";
import { useMount } from "../../utils/useMount";
import { cn } from "../../utils/tailwind";
const Popover = ({ placement, children, className }) => {
  const {
    triggerRef,
    overlayState,
    overlayProps: menuOverlayProps
  } = useMenuContext();
  const overlayRef = useRef(null);
  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement,
    isOpen: overlayState.isOpen,
    onClose: () => null
    // This stops the overlay from closing when the window scrolls. Hopefully this doesn't cause any issues. Closing on scroll isn't an issue IMO, however, it is not the same as the legacy menu implementation.
  });
  const { overlayProps } = useOverlay(
    {
      onClose: overlayState.close,
      isOpen: overlayState.isOpen,
      isDismissable: true
    },
    overlayRef
  );
  return overlayState.isOpen ? /* @__PURE__ */ jsx(Overlay, { children: /* @__PURE__ */ jsxs(
    "div",
    {
      className: "z-popover",
      ...mergeProps(menuOverlayProps, overlayProps, positionProps),
      ref: overlayRef,
      children: [
        /* @__PURE__ */ jsx(Listbox, { triggerRef, className, children: typeof children === "function" ? children(overlayState) : children }),
        /* @__PURE__ */ jsx(DismissButton, { onDismiss: overlayState.close })
      ]
    }
  ) }) : null;
};
const Listbox = ({
  triggerRef,
  className,
  children
}) => {
  const listboxRef = useRef(null);
  useMount(() => {
    if (triggerRef.current !== null && listboxRef.current !== null) {
      const theme = getCSSVar("--s-theme", triggerRef.current);
      listboxRef.current.dataset["theme"] = theme;
    }
  });
  return /* @__PURE__ */ jsx(
    "div",
    {
      ref: listboxRef,
      className: cn(
        "scrollbar border my-x1 min-w-[200px] overflow-auto rounded-md border-[--s-menu-popover-color-border] bg-[--s-menu-popover-color-bg] text-[--s-menu-color-text] shadow-soft",
        className
      ),
      "data-theme": "dark",
      children
    }
  );
};
export {
  Popover
};
