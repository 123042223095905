"use client";
import { useEffect, useRef } from "react";
const useMount = (effect) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      effect();
      mounted.current = true;
    }
  });
};
export {
  useMount
};
