"use client";
import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
import {
  useLinkComponent
} from "../utils/LinkComponentProvider";
import { tv } from "../utils/tailwind";
const button = tv({
  slots: {
    base: "tw-reset border focus-outline relative inline-flex h-x5 select-none items-center justify-center gap-x0_5 whitespace-nowrap rounded-md border-solid fill-current px-x1_5 text-xs font-normal no-underline transition-[padding] [&>svg]:size-x3 [&>svg]:fill-current [&>svg]:opacity-75",
    spinner: "absolute opacity-0 transition-opacity delay-75 content-['']"
  },
  variants: {
    variant: {
      primary: {
        base: "border-[--s-button-primary-color-border] bg-[--s-button-primary-color-bg] text-[--s-button-primary-color-text] hover:border-[--s-button-primary-hover-color-bg] hover:bg-[--s-button-primary-hover-color-bg] disabled:border-[--s-button-primary-disabled-color-bg] disabled:bg-[--s-button-primary-disabled-color-bg] disabled:text-[--s-button-primary-disabled-color-text]"
      },
      secondary: {
        base: "border-[--s-button-secondary-color-border] bg-[--s-button-secondary-color-bg] text-[--s-button-secondary-color-text] hover:border-[--s-button-secondary-hover-color-bg] hover:bg-[--s-button-secondary-hover-color-bg] disabled:border-[--s-button-secondary-disabled-color-bg] disabled:bg-[--s-button-secondary-disabled-color-bg] disabled:text-[--s-button-secondary-disabled-color-text]"
      },
      outline: {
        base: "border-[--s-button-outline-color-border] bg-[--s-button-outline-color-bg] text-[--s-button-outline-color-text] hover:border-[--s-button-outline-hover-color-bg] hover:bg-[--s-button-outline-hover-color-bg] disabled:border-[--s-button-outline-disabled-color-bg] disabled:bg-[--s-button-outline-disabled-color-bg] disabled:text-[--s-button-outline-disabled-color-text]"
      },
      subtle: {
        base: "border-[--s-button-subtle-color-border] bg-[--s-button-subtle-color-bg] text-[--s-button-subtle-color-text] hover:border-[--s-button-subtle-hover-color-bg] hover:bg-[--s-button-subtle-hover-color-bg] disabled:border-[--s-button-subtle-disabled-color-bg] disabled:bg-[--s-button-subtle-disabled-color-bg] disabled:text-[--s-button-subtle-disabled-color-text]"
      }
    },
    size: {
      large: {
        base: "",
        spinner: "top-[11px]"
      },
      medium: {
        base: "h-x4 text-tiny leading-[32px]",
        spinner: "top-[7px]"
      }
    },
    isDisabled: {
      true: {
        base: "cursor-default"
      }
    },
    isLoading: {
      true: {
        base: "pe-[38px]",
        spinner: "border end-x1_75 size-x2 animate-spin-fast rounded-x2 border-solid border-current border-b-transparent opacity-100"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    size: "large",
    isDisabled: false,
    isLoading: false
  }
});
const isButtonLink = (props) => {
  return "href" in props && props.href !== void 0;
};
const Button = forwardRef(
  ({ variant, size, className, children, isLoading, isDisabled, ...props }, ref) => {
    const { base, spinner } = button({
      variant,
      size,
      isLoading,
      isDisabled
    });
    if (isButtonLink(props)) {
      const Link = useLinkComponent();
      return /* @__PURE__ */ jsxs(
        Link,
        {
          ...props,
          ref,
          className: base({ className }),
          "aria-disabled": isDisabled || isLoading,
          onClick: props.onClick,
          children: [
            children,
            /* @__PURE__ */ jsx("span", { className: spinner(), "aria-hidden": "true" })
          ]
        }
      );
    }
    return /* @__PURE__ */ jsxs(
      "button",
      {
        ...props,
        ref,
        className: base({ className }),
        type: props.type ?? "button",
        disabled: isDisabled || isLoading,
        "aria-disabled": isDisabled || isLoading,
        children: [
          children,
          /* @__PURE__ */ jsx("span", { className: spinner(), "aria-hidden": "true" })
        ]
      }
    );
  }
);
Button.displayName = "Button";
export {
  Button,
  button
};
