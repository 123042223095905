"use client";
import { jsx, jsxs } from "react/jsx-runtime";
import { useRef, forwardRef } from "react";
import { useTextField } from "react-aria";
import { tv } from "../../utils/tailwind";
import { field } from "../base";
import { mergeRefs } from "../../utils/mergeRefs";
const textField = tv({
  extend: field
});
const TextField = forwardRef(
  ({
    id,
    type = "text",
    label,
    placeholder,
    value,
    defaultValue,
    name,
    prefix,
    postfix,
    description,
    error,
    className,
    isDisabled = false,
    isLoading = false,
    autoFocus = false,
    autoComplete = "off",
    inputMode,
    onChange,
    onBlur,
    onKeyDown,
    onFocus,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledby
  }, ref) => {
    const inputRef = useRef(null);
    const { labelProps, inputProps } = useTextField(
      {
        id,
        type,
        label,
        placeholder,
        value,
        defaultValue,
        description,
        name,
        autoFocus,
        autoComplete,
        onChange,
        inputMode,
        isDisabled,
        onKeyDown,
        onBlur: (e) => onBlur?.(
          e.currentTarget.value
        ),
        onFocus: (e) => onFocus?.(e),
        "aria-label": ariaLabel,
        "aria-labelledby": ariaLabelledby
      },
      inputRef
    );
    const hasError = error !== void 0 && error !== null && error !== "";
    const hasDescription = description !== void 0 && description !== "";
    const styles = textField({
      isDisabled,
      isLoading,
      hasError
    });
    return /* @__PURE__ */ jsxs(
      "fieldset",
      {
        "data-invalid": hasError,
        className: styles.fieldset({ className }),
        children: [
          label !== void 0 && /* @__PURE__ */ jsx("label", { ...labelProps, className: styles.label(), children: label }),
          /* @__PURE__ */ jsxs(
            "div",
            {
              "data-prefix": prefix !== void 0,
              "data-postfix": postfix !== void 0,
              className: styles.input(),
              children: [
                prefix !== void 0 && /* @__PURE__ */ jsx("span", { className: styles.inputPrefix(), children: prefix }),
                /* @__PURE__ */ jsx(
                  "input",
                  {
                    ...inputProps,
                    className: styles.inputEl(),
                    ref: mergeRefs(inputRef, ref)
                  }
                ),
                isLoading && /* @__PURE__ */ jsx("span", { className: styles.spinner() }),
                !isLoading && postfix !== void 0 && /* @__PURE__ */ jsx("span", { className: styles.inputPostfix(), children: postfix })
              ]
            }
          ),
          hasError && /* @__PURE__ */ jsx("span", { className: styles.error(), children: error }),
          hasDescription && !hasError && /* @__PURE__ */ jsx("span", { className: styles.description(), children: description })
        ]
      }
    );
  }
);
TextField.displayName = "TextField";
export {
  TextField,
  textField
};
