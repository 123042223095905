import { jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import {
  useLinkComponent
} from "../utils/LinkComponentProvider";
import { tv } from "../utils/tailwind";
const link = tv({
  slots: {
    base: "tw-reset focus-outline text-xs text-[--s-link-primary-color-text] underline decoration-[--s-link-primary-color-underline] decoration-1 underline-offset-2 hover:decoration-[--s-link-primary-color-hover-underline]"
  },
  variants: {},
  defaultVariants: {}
});
const { base } = link();
const Link = forwardRef(
  ({ className, children, ...props }, ref) => {
    const Link2 = useLinkComponent();
    return /* @__PURE__ */ jsx(Link2, { ...props, ref, className: base({ className }), children });
  }
);
Link.displayName = "Button";
export {
  Link,
  link
};
