import { jsx } from "react/jsx-runtime";
import { useCallback, useRef, useLayoutEffect } from "react";
import {
  Dialog as AriaDialog,
  Modal as AriaModal,
  ModalOverlay as AriaModalOverlay
} from "react-aria-components";
import { cn } from "../../utils/tailwind";
import { getCSSVar } from "../../utils/css";
const Dialog = ({
  isOpen,
  children,
  className,
  isDismissable,
  onClose,
  "aria-label": ariaLabel
}) => {
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);
  const overlayRef = useRef(null);
  useLayoutEffect(() => {
    if (overlayRef.current !== null) {
      const theme = getCSSVar(
        "--s-theme",
        overlayRef.current?.ownerDocument?.body
      );
      overlayRef.current.dataset["theme"] = theme;
    }
  }, [isOpen]);
  return /* @__PURE__ */ jsx(
    AriaModalOverlay,
    {
      ref: overlayRef,
      isDismissable,
      isKeyboardDismissDisabled: !isDismissable,
      isOpen,
      onOpenChange: handleClose,
      className: cn(
        "fixed left-0 top-0 z-overlay",
        "h-[--visual-viewport-height] w-full p-safe",
        "flex items-center justify-center",
        "bg-[--s-modal-overlay-color-bg] backdrop-blur-sm transition-colors duration-200",
        "[&[data-entering]]:bg-p-grey-180/0",
        "[&[data-exiting]]:bg-p-grey-180/0"
      ),
      "data-theme": "dark",
      children: /* @__PURE__ */ jsx(
        AriaModal,
        {
          isDismissable,
          isKeyboardDismissDisabled: !isDismissable,
          isOpen,
          onOpenChange: handleClose,
          children: /* @__PURE__ */ jsx(
            AriaDialog,
            {
              className: cn(
                "z-modal flex flex-col gap-x1",
                "relative rounded-x1 bg-surface-1 shadow-soft",
                "m-x2 max-h-[calc(theme(height.screen-safe)-theme(spacing.x4))]",
                "lg:m-x4 lg:max-h-[calc(theme(height.screen-safe)-theme(spacing.x8))]",
                "tw-reset max-w-[400px] text-solid",
                className
              ),
              "aria-label": ariaLabel,
              children
            }
          )
        }
      )
    }
  );
};
export {
  Dialog
};
