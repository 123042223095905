const getCSSVar = (name, element) => {
  try {
    if (window?.document?.body !== void 0) {
      const el = element !== void 0 ? element : window.document.body;
      return window.getComputedStyle(el).getPropertyValue(name).replace("'", "").replace("'", "").replace('"', "").replace('"', "");
    }
  } catch (error) {
    console.error(error);
  }
  return void 0;
};
export {
  getCSSVar
};
