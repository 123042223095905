import { jsx } from "react/jsx-runtime";
import { useMenu } from "@react-aria/menu";
import { useTreeState } from "@react-stately/tree";
import {
  useRef
} from "react";
import { useMenuContext } from "./MenuContext";
import { ListItem } from "./ListItem";
import { ListSection } from "./ListSection";
const List = ({ children, onAction }) => {
  const { menuProps: menuOptions, overlayState } = useMenuContext();
  const treeState = useTreeState({
    children: Array.isArray(children) ? children : [children]
  });
  const ref = useRef(null);
  const { menuProps } = useMenu(
    {
      ...menuOptions,
      onClose: overlayState.close,
      onAction
    },
    treeState,
    ref
  );
  return /* @__PURE__ */ jsx(
    "ul",
    {
      ...menuProps,
      ref,
      className: "overflow-auto rounded-md",
      role: "menu",
      children: [...treeState.collection].map((item) => {
        if (item.type === "section") {
          return /* @__PURE__ */ jsx(
            ListSection,
            {
              section: item,
              state: treeState
            },
            item.key
          );
        }
        return /* @__PURE__ */ jsx(ListItem, { item, state: treeState }, item.key);
      })
    }
  );
};
export {
  List
};
