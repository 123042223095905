"use client";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Item, Section } from "@react-stately/collections";
import { List } from "./components/List";
import { Trigger } from "./components/Trigger";
import { Popover } from "./components/Popover";
import { MenuProvider } from "./components/MenuContext";
const Menu = ({
  label,
  className,
  placement = "bottom start",
  children,
  onOpen
}) => {
  const handleOpen = useCallback(
    (isOpen) => isOpen && onOpen?.(),
    [onOpen]
  );
  return /* @__PURE__ */ jsxs(MenuProvider, { onOpenChange: handleOpen, children: [
    /* @__PURE__ */ jsx(Trigger, { label }),
    /* @__PURE__ */ jsx(Popover, { placement, className, children: /* @__PURE__ */ jsx(List, { children }) })
  ] });
};
Menu.Item = Item;
Menu.Section = Section;
export {
  Menu
};
