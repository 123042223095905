import { clsx } from "clsx";
import {
  extendTailwindMerge,
  mergeConfigs,
  getDefaultConfig
} from "tailwind-merge";
import { createTV } from "tailwind-variants";
import { theme } from "../preset";
const twMergeConfig = mergeConfigs(getDefaultConfig(), {
  override: {
    theme: {
      borderRadius: Object.keys(theme.borderRadius),
      colors: Object.keys(theme.colors),
      spacing: Object.keys(theme.spacing)
    }
  },
  extend: {
    classGroups: {
      shadow: Object.keys(theme.extend.boxShadow).map((i) => `shadow-${i}`),
      "font-family": Object.keys(theme.extend.fontFamily).map(
        (i) => `font-${i}`
      ),
      "font-weight": Object.keys(theme.extend.fontWeight).map(
        (i) => `font-${i}`
      ),
      "text-color": Object.keys(theme.extend.textColor).map((i) => `text-${i}`),
      "font-size": Object.keys(theme.fontSize).map((i) => `text-${i}`)
    }
  }
});
const merge = extendTailwindMerge(twMergeConfig);
const cn = (...inputs) => merge(clsx(inputs));
const tv = createTV({ twMergeConfig });
export {
  cn,
  tv
};
