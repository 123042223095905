"use client";
import { jsx } from "react/jsx-runtime";
import { useFocusVisibleListener } from "@react-aria/interactions";
import {
  LinkComponentProvider
} from "./utils/LinkComponentProvider";
const useKeyboardFocus = () => {
  useFocusVisibleListener(
    (isFocusVisible) => {
      if (isFocusVisible) {
        window.document.body.classList.add("s-focus-kb");
      } else {
        window.document.body.classList.remove("s-focus-kb");
      }
    },
    [],
    { isTextInput: true }
  );
};
const UICoreNextProvider = ({ linkComponent, children }) => {
  useKeyboardFocus();
  return /* @__PURE__ */ jsx(LinkComponentProvider, { value: linkComponent, children });
};
export {
  UICoreNextProvider,
  useKeyboardFocus
};
