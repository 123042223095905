import { jsxs } from "react/jsx-runtime";
import { useTooltipTriggerState } from "@react-stately/tooltip";
import {
  Children,
  cloneElement,
  isValidElement,
  useRef
} from "react";
import { mergeProps, useIsSSR, useTooltipTrigger } from "react-aria";
import { TooltipProvider } from "./TooltipContext";
const Trigger = ({
  children,
  delay = 250,
  placement = "top"
}) => {
  const ref = useRef(null);
  const state = useTooltipTriggerState({ delay, closeDelay: 500 });
  const { triggerProps, tooltipProps } = useTooltipTrigger({}, state, ref);
  const isSSR = useIsSSR();
  if (isSSR) {
    return children;
  }
  const childArray = Children.toArray(children);
  const triggerChild = childArray[0];
  const otherChildren = childArray.slice(1);
  if (!isValidElement(triggerChild)) {
    throw new Error(
      "Tooltip.Trigger: The first child element must be an interactable element (<button> or <a>). This is so the correct focus semantics can be applied."
    );
  }
  const triggerType = triggerChild.type;
  if (typeof triggerType === "string" && triggerType !== "button" && triggerType !== "a") {
    throw new Error(
      "Tooltip.Trigger: The first child element must be an interactable element (<button> or <a>). This is so the correct focus semantics can be applied."
    );
  }
  return /* @__PURE__ */ jsxs(
    TooltipProvider,
    {
      state,
      tooltipProps,
      triggerRef: ref,
      placement,
      children: [
        cloneElement(triggerChild, {
          ref,
          ...mergeProps(triggerChild.props, triggerProps, {
            // On touch devices, allow a press to show the tooltip.
            // This supports the usecase of a contextual help icon - historically, tooltip's have shown on touch events in sws mobile apps.
            onClick: (e) => {
              if (e.nativeEvent.pointerType === "touch") {
                state.open(true);
              }
            }
          })
        }),
        otherChildren
      ]
    }
  );
};
export {
  Trigger
};
