import plugin from "tailwindcss/plugin";
import {
  sliderPlugin,
  scrollbarPlugin,
  borderPlugin,
  bonesPlugin,
  focusPlugin,
  touchPlugin,
  safeAreaPlugin
} from "./plugins";
const theme = {
  colors: {
    current: "currentColor",
    p: {
      black: {
        DEFAULT: "hsl(var(--s-tw-color-p-black-100) / <alpha-value>)",
        100: "hsl(var(--s-tw-color-p-black-100) / <alpha-value>)"
      },
      white: {
        DEFAULT: "hsl(var(--s-tw-color-p-white-100) / <alpha-value>)",
        100: "hsl(var(--s-tw-color-p-white-100) / <alpha-value>)",
        110: "hsl(var(--s-tw-color-p-white-110) / <alpha-value>)",
        120: "hsl(var(--s-tw-color-p-white-120) / <alpha-value>)",
        130: "hsl(var(--s-tw-color-p-white-130) / <alpha-value>)",
        140: "hsl(var(--s-tw-color-p-white-140) / <alpha-value>)",
        150: "hsl(var(--s-tw-color-p-white-150) / <alpha-value>)",
        170: "hsl(var(--s-tw-color-p-white-170) / <alpha-value>)"
      },
      blue: {
        50: "hsl(var(--s-tw-color-p-blue-50) / <alpha-value>)",
        60: "hsl(var(--s-tw-color-p-blue-60) / <alpha-value>)",
        DEFAULT: "hsl(var(--s-tw-color-p-blue-100) / <alpha-value>)",
        100: "hsl(var(--s-tw-color-p-blue-100) / <alpha-value>)",
        110: "hsl(var(--s-tw-color-p-blue-110) / <alpha-value>)",
        120: "hsl(var(--s-tw-color-p-blue-120) / <alpha-value>)",
        130: "hsl(var(--s-tw-color-p-blue-130) / <alpha-value>)"
      },
      red: {
        DEFAULT: "hsl(var(--s-tw-color-p-red-100) / <alpha-value>)",
        100: "hsl(var(--s-tw-color-p-red-100) / <alpha-value>)"
      },
      green: {
        DEFAULT: "hsl(var(--s-tw-color-p-green-100) / <alpha-value>)",
        100: "hsl(var(--s-tw-color-p-green-100) / <alpha-value>)"
      },
      orange: {
        DEFAULT: "hsl(var(--s-tw-color-p-orange-100) / <alpha-value>)",
        100: "hsl(var(--s-tw-color-p-orange-100) / <alpha-value>)"
      },
      yellow: {
        DEFAULT: "hsl(var(--s-tw-color-p-yellow-100) / <alpha-value>)",
        100: "hsl(var(--s-tw-color-p-yellow-100) / <alpha-value>)"
      },
      purple: {
        DEFAULT: "hsl(var(--s-tw-color-p-purple-100) / <alpha-value>)",
        100: "hsl(var(--s-tw-color-p-purple-100) / <alpha-value>)"
      },
      grey: {
        10: "hsl(var(--s-tw-color-p-grey-10) / <alpha-value>)",
        20: "hsl(var(--s-tw-color-p-grey-20) / <alpha-value>)",
        30: "hsl(var(--s-tw-color-p-grey-30) / <alpha-value>)",
        50: "hsl(var(--s-tw-color-p-grey-50) / <alpha-value>)",
        100: "hsl(var(--s-tw-color-p-grey-100) / <alpha-value>)",
        120: "hsl(var(--s-tw-color-p-grey-120) / <alpha-value>)",
        180: "hsl(var(--s-tw-color-p-grey-180) / <alpha-value>)"
      }
    },
    legacy: {
      white: "hsl(var(--s-tw-color-legacy-white) / <alpha-value>)",
      palewhite: "hsl(var(--s-tw-color-legacy-palewhite) / <alpha-value>)",
      paper: "hsl(var(--s-tw-color-legacy-palewhite) / <alpha-value>)",
      lightgray: "hsl(var(--s-tw-color-legacy-lightgray) / <alpha-value>)",
      mediumgray: "hsl(var(--s-tw-color-legacy-mediumgray) / <alpha-value>)",
      darkgray: "hsl(var(--s-tw-color-legacy-darkgray) / <alpha-value>)",
      darkergray: "hsl(var(--s-tw-color-legacy-darkergray) / <alpha-value>)",
      spacegray: "hsl(var(--s-tw-color-legacy-spacegray) / <alpha-value>)",
      phantom: "hsl(var(--s-tw-color-legacy-phantom) / <alpha-value>)",
      noir: "hsl(var(--s-tw-color-legacy-noir) / <alpha-value>)",
      charcoal: "hsl(var(--s-tw-color-legacy-charcoal) / <alpha-value>)",
      almostblack: "hsl(var(--s-tw-color-legacy-almostblack) / <alpha-value>)"
    },
    accent: "var(--s-color-accent)",
    brand: {
      "01": {
        DEFAULT: "var(--s-color-brand-01)",
        soft: "var(--s-color-brand-01-soft)",
        softer: "var(--s-color-brand-01-softer)",
        subtle: "var(--s-color-brand-01-subtle)"
      },
      "02": "var(--s-color-brand-02)",
      "03": {
        DEFAULT: "var(--s-color-brand-03)",
        subtle: "var(--s-color-brand-03-subtle)"
      },
      "04": "var(--s-color-brand-01)"
    },
    chart: {
      "01": {
        DEFAULT: "var(--s-color-chart-01)",
        soft: "var(--s-color-chart-01-soft)",
        softer: "var(--s-color-chart-01-softer)",
        subtle: "var(--s-color-chart-01-subtle)"
      },
      "02": {
        DEFAULT: "var(--s-color-chart-02)",
        soft: "var(--s-color-chart-02-soft)",
        softer: "var(--s-color-chart-02-softer)",
        subtle: "var(--s-color-chart-02-subtle)"
      },
      "03": {
        DEFAULT: "var(--s-color-chart-03)",
        soft: "var(--s-color-chart-03-soft)",
        softer: "var(--s-color-chart-03-softer)",
        subtle: "var(--s-color-chart-03-subtle)"
      },
      "04": {
        DEFAULT: "var(--s-color-chart-04)",
        soft: "var(--s-color-chart-04-soft)",
        softer: "var(--s-color-chart-04-softer)",
        subtle: "var(--s-color-chart-04-subtle)"
      },
      "05": {
        DEFAULT: "var(--s-color-chart-05)",
        soft: "var(--s-color-chart-05-soft)",
        softer: "var(--s-color-chart-05-softer)",
        subtle: "var(--s-color-chart-05-subtle)"
      },
      "06": {
        DEFAULT: "var(--s-color-chart-06)",
        soft: "var(--s-color-chart-06-soft)",
        softer: "var(--s-color-chart-06-softer)",
        subtle: "var(--s-color-chart-06-subtle)"
      }
    },
    transparent: {
      DEFAULT: "transparent",
      strong: "var(--s-color-transparent-strong)",
      subtle: "var(--s-color-transparent-subtle)"
    },
    attention: {
      DEFAULT: "var(--s-color-attention)",
      subtle: "var(--s-color-attention-subtle)",
      strong: "var(--s-color-attention-strong)"
    },
    good: {
      DEFAULT: "var(--s-color-good)",
      subtle: "var(--s-color-good-subtle)",
      strong: "var(--s-color-good-strong)"
    },
    bad: {
      DEFAULT: "var(--s-color-bad)",
      subtle: "var(--s-color-bad-subtle)",
      strong: "var(--s-color-bad-strong)"
    },
    warning: {
      DEFAULT: "var(--s-color-warning)",
      subtle: "var(--s-color-warning-subtle)",
      strong: "var(--s-color-warning-strong)"
    }
  },
  // Override spacing as it uses rem by default, which doesn't jive well with our
  // root font size being 10px.
  // TODO: resolve and convert back to rem.
  spacing: {
    inherit: "inherit",
    "0": "0px",
    px: "1px",
    "0.5": "2px",
    "1": "4px",
    "1.5": "6px",
    "2": "8px",
    "2.5": "10px",
    "3": "12px",
    "3.5": "14px",
    "4": "16px",
    "5": "20px",
    "6": "24px",
    "7": "28px",
    "8": "32px",
    "9": "36px",
    "10": "40px",
    "11": "44px",
    "12": "48px",
    "14": "56px",
    "16": "64px",
    "20": "80px",
    "24": "96px",
    "28": "112px",
    "32": "128px",
    "36": "144px",
    "40": "160px",
    "44": "176px",
    "48": "192px",
    "52": "208px",
    "56": "224px",
    "60": "240px",
    "64": "256px",
    "72": "288px",
    "80": "320px",
    "96": "384px",
    x0: "0px",
    x0_25: "2px",
    x0_5: "4px",
    x0_75: "6px",
    x1: "8px",
    x1_25: "10px",
    x1_5: "12px",
    x1_75: "14px",
    x2: "16px",
    x3: "24px",
    x4: "32px",
    x5: "40px",
    x6: "48px",
    x7: "56px",
    x8: "64px",
    x9: "72px",
    x10: "80px",
    x11: "88px",
    x12: "96px",
    x13: "104px",
    x14: "112px",
    x15: "120px",
    x16: "128px",
    x17: "136px",
    x18: "144px",
    x19: "152px",
    x20: "160px"
  },
  // Override border radius as it uses rem by default, which doesn't jive well with our
  // root font size being 10px.
  // TODO: resolve and convert back to rem.
  borderRadius: {
    none: "0px",
    sm: "3px",
    md: "6px",
    lg: "12px",
    // Extended radius sizing based on space scale - TODO: investigate moving to semantic border
    // radius tokens.
    x0: "0px",
    x0_25: "2px",
    x0_5: "4px",
    x0_75: "6px",
    x1: "8px",
    x1_25: "10px",
    x1_5: "12px",
    x1_75: "14px",
    x2: "16px",
    x3: "24px",
    x4: "32px",
    x5: "40px",
    x6: "48px",
    x7: "56px",
    x8: "64px",
    x9: "72px",
    x10: "80px",
    x11: "88px",
    x12: "96px",
    x13: "104px",
    x14: "112px",
    x15: "120px",
    x16: "128px",
    x17: "136px",
    x18: "144px",
    x19: "152px",
    x20: "160px",
    // Tailwind default radius' - kept for interoperability with 'standard' tailwind setups
    DEFAULT: "4px",
    xl: "12px",
    "2xl": "16px",
    "3xl": "24px",
    full: "9999px"
  },
  fontSize: {
    "2xl": ["24px", "32px"],
    "3xl": ["30px", "36px"],
    "4xl": ["36px", "40px"],
    "5xl": ["48px", "1"],
    "6xl": ["60px", "1"],
    "7xl": ["72px", "1"],
    "8xl": ["96px", "1"],
    "9xl": ["128px", "1"],
    micro: ["var(--s-text-micro-size)", "var(--s-text-micro-line-height)"],
    tiny: ["var(--s-text-tiny-size)", "var(--s-text-tiny-line-height)"],
    xs: ["var(--s-text-xs-size)", "var(--s-text-xs-line-height)"],
    sm: ["var(--s-text-sm-size)", "var(--s-text-sm-line-height)"],
    base: ["var(--s-text-base-size)", "var(--s-text-base-line-height)"],
    lg: ["var(--s-text-lg-size)", "var(--s-text-lg-line-height)"],
    xl: ["var(--s-text-xl-size)", "var(--s-text-xl-line-height)"],
    mega: ["var(--s-text-mega-size)", "var(--s-text-mega-line-height)"],
    hero: ["var(--s-text-hero-size)", "var(--s-text-hero-line-height)"],
    mass: ["var(--s-text-mass-size)", "var(--s-text-mass-line-height)"],
    uber: ["var(--s-text-uber-size)", "var(--s-text-uber-line-height)"]
  },
  // Mobile-first adaptive breakpoints
  // => @media (min-width: 576px) { ... }
  screens: {
    sm: "576px",
    md: "768px",
    lg: "1024px",
    xl: "1200px",
    // Tailwind default, kept for interoperability / future use
    "2xl": "1536px"
  },
  extend: {
    backgroundColor: {
      surface: {
        DEFAULT: "var(--s-color-surface)",
        "1": "var(--s-color-surface-1)",
        "2": "var(--s-color-surface-2)",
        "3": "var(--s-color-surface-3)"
      }
    },
    borderColor: {
      DEFAULT: "var(--s-color-border-soft)",
      strong: "var(--s-color-border-strong)",
      soft: "var(--s-color-border-soft)",
      subtle: "var(--s-color-border-subtle)"
    },
    boxShadow: {
      "border-strong": "var(--s-shadow-border-strong)",
      "border-subtle": "var(--s-shadow-border-subtle)",
      diffuse: "var(--s-shadow-diffuse)",
      soft: "var(--s-shadow-soft)",
      crisp: "var(--s-shadow-crisp)"
    },
    textColor: {
      solid: "var(--s-color-text-solid)",
      soft: "var(--s-color-text-soft)",
      softer: "var(--s-color-text-softer)",
      subtle: "var(--s-color-text-subtle)"
    },
    textDecorationColor: {
      current: "currentColor",
      inherit: "inherit",
      transparent: "transparent",
      solid: "var(--s-color-text-solid)",
      soft: "var(--s-color-text-soft)",
      softer: "var(--s-color-text-softer)",
      subtle: "var(--s-color-text-subtle)"
    },
    fill: {
      none: "none",
      current: "currentColor",
      inherit: "inherit",
      transparent: "transparent",
      solid: "var(--s-color-text-solid)",
      soft: "var(--s-color-text-soft)",
      softer: "var(--s-color-text-softer)",
      subtle: "var(--s-color-text-subtle)"
    },
    stroke: {
      none: "none",
      current: "currentColor",
      inherit: "inherit",
      transparent: "transparent",
      solid: "var(--s-color-text-solid)",
      soft: "var(--s-color-text-soft)",
      softer: "var(--s-color-text-softer)",
      subtle: "var(--s-color-text-subtle)"
    },
    fontFamily: {
      base: [
        "Roboto",
        "-apple-system",
        "system-ui",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif"
      ],
      sans: [
        "Roboto",
        "-apple-system",
        "system-ui",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif"
      ],
      condensed: [
        '"Roboto Condensed"',
        "Roboto",
        "-apple-system",
        "system-ui",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif"
      ]
    },
    fontWeight: {
      strong: "500"
    },
    typography: {
      DEFAULT: {
        css: {
          "--tw-prose-body": "var(--s-color-text-solid)",
          "--tw-prose-headings": "var(--s-color-text-solid)",
          "--tw-prose-lead": "var(--s-color-text-solid)",
          "--tw-prose-links": "var(--s-color-accent)",
          "--tw-prose-bold": "var(--s-color-text-solid)",
          "--tw-prose-bullets": "var(--s-color-text-solid)",
          "--tw-prose-hr": "var(--s-color-border-subtle)"
        }
      }
    },
    animation: {
      "spin-fast": "spin 0.5s linear infinite",
      "fade-in": "fadeIn 0.5s ease-out"
    },
    keyframes: {
      fadeIn: {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" }
      }
    },
    zIndex: {
      "1": "1",
      deep: "var(--s-index-deep)",
      base: "var(--s-index-base)",
      sticky: "var(--s-index-sticky)",
      nav: "var(--s-index-nav)",
      overlay: "var(--s-index-overlay)",
      popover: "var(--s-index-popover)",
      toast: "var(--s-index-toast)",
      modal: "var(--s-index-modal)"
    }
  }
};
const preset = {
  content: ["./src/**/*.{js,ts,jsx,tsx,mdx}"],
  experimental: {
    /**
     * Removes the expansive application of tailwind CSS variables
     * @see https://github.com/tailwindlabs/tailwindcss/discussions/7317#discussioncomment-2107898
     */
    optimizeUniversalDefaults: true
  },
  // Disable the base tailwind reset as this conflicts with some existing styles and expected layout.
  // TODO: re-enable this and fix issues.
  corePlugins: {
    preflight: false
  },
  darkMode: ["variant", ['[data-theme="dark"]&', '[data-theme="dark"] &']],
  theme,
  plugins: [
    sliderPlugin,
    scrollbarPlugin,
    borderPlugin,
    bonesPlugin,
    focusPlugin,
    touchPlugin,
    safeAreaPlugin,
    require("@tailwindcss/container-queries"),
    require("@tailwindcss/aspect-ratio"),
    require("@tailwindcss/typography"),
    // Add explicit dark / light modes
    plugin(({ addVariant }) => {
      addVariant("light", ['[data-theme="light"]&', '[data-theme="light"] &']);
    })
  ]
};
export {
  preset,
  theme
};
