import { tv } from "../utils/tailwind";
const field = tv({
  slots: {
    fieldset: "tw-reset group/field m-0 flex flex-col gap-x0_75",
    label: "tw-reset m-0 text-xs text-solid opacity-70 transition-opacity group-focus-within/field:opacity-100",
    inputEl: "tw-reset focus-outline h-x5 w-full select-none rounded-x0_5 bg-transparent p-x1_75 pe-x5 text-xs text-[--s-field-input-color-text] caret-brand-01 group-data-[prefix=true]/input:ps-x5",
    input: [
      "tw-reset group/input relative h-x5 rounded-x0_5 p-0 text-xs font-normal",
      "border border-solid border-[--s-field-input-color-border]",
      "bg-[--s-field-input-color-bg]",
      "opacity-70 transition-opacity",
      "group-focus-within/field:bg-[--s-field-input-focus-color-bg] group-focus-within/field:opacity-100",
      "inline-flex items-center justify-center gap-x0_5"
    ],
    inputPrefix: [
      "absolute start-x1_25 flex items-center [&:not(:has(button)):not(:has(a))]:pointer-events-none",
      "fill-[--s-field-input-color-text] opacity-70 transition-opacity",
      "text-[--s-field-input-color-text]"
    ],
    inputPostfix: [
      "absolute end-x1_25 flex items-center [&:not(:has(button)):not(:has(a))]:pointer-events-none",
      "fill-[--s-field-input-color-text] opacity-70 transition-opacity",
      "text-[--s-field-input-color-text]"
    ],
    spinner: [
      "absolute end-x1_75 size-x2",
      "animate-spin-fast opacity-0 transition-opacity delay-75",
      "border rounded-x2 border-solid border-[--s-field-input-color-text] border-b-transparent"
    ],
    error: "-mt-x0_25 text-tiny text-solid opacity-90 transition-opacity group-focus-within/field:opacity-100",
    description: "-mt-x0_25 text-tiny text-solid opacity-70 transition-opacity group-focus-within/field:opacity-90"
  },
  variants: {
    isDisabled: {
      true: {
        label: "opacity-30",
        inputEl: "cursor-not-allowed",
        input: "opacity-30"
      }
    },
    isLoading: {
      true: {
        input: "",
        spinner: ["opacity-70", "group-focus-within/field:opacity-100"]
      }
    },
    hasError: {
      true: {
        input: "border-[--s-field-input-error-color-border]",
        error: "text-[--s-field-input-error-color-text]"
      }
    }
  },
  defaultVariants: {
    isDisabled: false,
    isLoading: false,
    hasError: false
  }
});
export {
  field
};
