import { jsx, jsxs } from "react/jsx-runtime";
import { useOption } from "@react-aria/listbox";
import { useLayoutEffect, useRef } from "react";
import { cn } from "../../../utils/tailwind";
const ListBoxOption = ({ item, state }) => {
  const ref = useRef(null);
  const { optionProps, isFocused, isSelected } = useOption(
    { key: item.key, shouldFocusOnHover: true },
    state,
    ref
  );
  useLayoutEffect(() => {
    if (state.selectionManager.focusedKey === null && item.key === state.collection.getFirstKey()) {
      state.selectionManager.setFocusedKey(item.key);
    }
  }, [
    item.key,
    state.collection,
    state.selectionManager,
    state.selectionManager.focusedKey
  ]);
  return /* @__PURE__ */ jsxs(
    "li",
    {
      "data-focused": isFocused ? "true" : "false",
      "data-selected": isSelected ? "true" : "false",
      className: cn(
        "relative grid min-h-x5 grid-cols-[minmax(0,1fr)_min-content] items-center px-x1_25",
        "cursor-pointer",
        "aria-selected:bg-[--s-field-input-popover-hover-color-bg]",
        'focus-within:bg-[--s-field-input-popover-hover-color-bg] data-[focused="true"]:bg-[--s-field-input-popover-hover-color-bg]',
        'focus-within:text-[--s-field-input-popover-hover-color-text] data-[focused="true"]:text-[--s-field-input-popover-hover-color-text]',
        item.props?.className
      ),
      children: [
        /* @__PURE__ */ jsx("div", { ...optionProps, ref, children: item.rendered }),
        item?.props?.postfix !== void 0 && /* @__PURE__ */ jsx("div", { className: "flex h-full items-center text-nowrap fill-current", children: item?.props?.postfix })
      ]
    }
  );
};
export {
  ListBoxOption
};
