import { jsx } from "react/jsx-runtime";
import { tv } from "../utils/tailwind";
const badge = tv({
  base: "inline-block h-min truncate whitespace-nowrap text-center",
  variants: {
    variant: {
      solid: ""
    },
    tone: {
      default: "",
      attention: "",
      good: "",
      bad: "",
      warning: ""
    },
    size: {
      default: "rounded-xl px-2 py-[3px] text-tiny",
      small: "rounded-md px-1.5 py-px text-micro"
    }
  },
  compoundVariants: [
    {
      variant: "solid",
      tone: "attention",
      class: "bg-attention text-p-white-100"
    },
    {
      variant: "solid",
      tone: "good",
      class: "bg-good-subtle text-good"
    },
    {
      variant: "solid",
      tone: "bad",
      class: "bg-bad-subtle text-bad"
    },
    {
      variant: "solid",
      tone: "warning",
      class: "bg-warning-subtle text-warning"
    },
    {
      variant: "solid",
      tone: "default",
      class: "bg-transparent-subtle text-soft"
    }
  ],
  defaultVariants: {
    variant: "solid",
    tone: "default",
    size: "default"
  }
});
const Badge = ({ variant, tone, className, size, children }) => {
  return /* @__PURE__ */ jsx("mark", { className: badge({ variant, tone, size, className }), children });
};
export {
  Badge,
  badge
};
