import { jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { useMenuItem } from "@react-aria/menu";
import { cn } from "../../utils/tailwind";
const ListItem = ({ item, state }) => {
  const ref = useRef(null);
  const { menuItemProps, isDisabled, isSelected, isFocused } = useMenuItem(
    { key: item.key },
    state,
    ref
  );
  useEffect(() => {
    const handleTouchEnd = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const element = ref.current;
    if (element !== null) {
      element.addEventListener("touchend", handleTouchEnd);
      return () => element.removeEventListener("touchend", handleTouchEnd);
    }
  }, []);
  return /* @__PURE__ */ jsx(
    "li",
    {
      ...menuItemProps,
      className: cn(
        "flex cursor-pointer items-center gap-x1 px-x2 py-x1 text-xs text-soft hover:bg-[--s-menu-popover-hover-color-bg] hover:text-[--s-menu-popover-hover-color-text]",
        isDisabled && "cursor-not-allowed text-subtle",
        isFocused && "bg-[--s-menu-popover-hover-color-bg] text-[--s-menu-popover-hover-color-text]",
        isSelected || item?.props?.isSelected && "font-strong text-solid",
        item.props?.className
      ),
      ref,
      children: item.rendered
    }
  );
};
export {
  ListItem
};
