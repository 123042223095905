import plugin from "tailwindcss/plugin";
const sliderPlugin = ({
  addUtilities,
  addVariant,
  theme
}) => {
  addUtilities({
    ".slider": {
      cursor: "pointer",
      appearance: "none",
      "&::-webkit-slider-thumb": {
        appearance: "none",
        width: "1rem",
        height: "1rem",
        backgroundColor: theme("colors.gray.400", "#9ca3af")
      },
      "&::-moz-range-thumb": {
        appearance: "none",
        width: "1rem",
        height: "1rem",
        backgroundColor: theme("colors.gray.400", "#9ca3af"),
        border: "none"
        // Firefox: Remove border
      },
      "&::-webkit-slider-runnable-track": {
        backgroundColor: theme("borderColor.DEFAULT", "currentColor")
      },
      "&::-moz-range-track": {
        backgroundColor: theme("borderColor.DEFAULT", "currentColor")
      }
    }
  });
  addVariant("slider-thumb", [
    "&::-webkit-slider-thumb",
    "&::-moz-range-thumb"
  ]);
  addVariant("slider-track", [
    "&::-webkit-slider-runnable-track",
    "&::-moz-range-track"
  ]);
  addVariant("slider-resizer", "&::-webkit-resizer");
};
const scrollbarPlugin = ({
  addUtilities,
  addComponents
}) => {
  addComponents({
    ".scrollbar": {
      "@supports not selector(::-webkit-scrollbar)": {
        "scrollbar-width": "8px",
        "scrollbar-color": "var(--s-scrollbar-thumb-bg-color) transparent"
      },
      "&::-webkit-scrollbar": {
        display: "block",
        width: "8px",
        height: "8px"
      },
      "&::-webkit-scrollbar-thumb": {
        "border-radius": "8px",
        "background-color": "var(--s-scrollbar-thumb-bg-color)"
      },
      "&::-webkit-scrollbar-thumb:hover": {
        "background-color": "var(--s-scrollbar-thumb-hover-bg-color)"
      },
      "&::-webkit-scrollbar-track": {
        "background-color": "transparent"
      },
      "&::-webkit-scrollbar-corner": {
        "background-color": "transparent"
      }
    }
  });
  addUtilities({
    ".scrollbar-hidden": {
      "scrollbar-width": "none",
      "&::-webkit-scrollbar": { display: "none" }
    }
  });
};
const borderPlugin = ({ addComponents, theme }) => {
  addComponents({
    ".border": {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme("borderColor.DEFAULT", "currentColor")
    }
  });
};
const bonesPlugin = ({ addComponents }) => {
  addComponents({
    ".bones": {
      display: "block",
      "@apply rounded-x0_25 bg-p-white-100/10 light:bg-p-black-100/10": {}
    }
  });
};
const focusPlugin = ({ addComponents }) => {
  addComponents({
    ".focus-outline": {
      "@apply focus-visible:[&:not(input)]:outline-dotted focus-visible:[&:not(input)]:outline-2 focus-visible:[&:not(input)]:outline-offset-2 focus-visible:[&:not(input)]:outline-[--s-focus-color] focus-visible:[.s-focus-kb_input&]:outline-dotted focus-visible:[.s-focus-kb_input&]:outline-2 focus-visible:[.s-focus-kb_input&]:outline-offset-2 focus-visible:[.s-focus-kb_input&]:outline-[--s-focus-color]": {}
    }
  });
};
const touchPlugin = ({ addVariant }) => {
  addVariant("touch", "@media (pointer: coarse)");
};
const safeAreaPlugin = plugin(
  ({ addUtilities }) => {
    addUtilities({
      ".p-safe": {
        padding: "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
      },
      ".px-safe": {
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)"
      },
      ".py-safe": {
        paddingTop: "env(safe-area-inset-top)",
        paddingBottom: "env(safe-area-inset-bottom)"
      },
      ".pr-safe": {
        paddingRight: "env(safe-area-inset-right)"
      },
      ".pb-safe": {
        paddingBottom: "env(safe-area-inset-bottom)"
      },
      ".pl-safe": {
        paddingLeft: "env(safe-area-inset-left)"
      },
      ".m-safe": {
        margin: "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
      },
      ".mx-safe": {
        marginLeft: "env(safe-area-inset-left)",
        marginRight: "env(safe-area-inset-right)"
      },
      ".my-safe": {
        marginTop: "env(safe-area-inset-top)",
        marginBottom: "env(safe-area-inset-bottom)"
      },
      ".mt-safe": {
        marginTop: "env(safe-area-inset-top)"
      },
      ".mr-safe": {
        marginRight: "env(safe-area-inset-right)"
      },
      ".mb-safe": {
        marginBottom: "env(safe-area-inset-bottom)"
      },
      ".ml-safe": {
        marginLeft: "env(safe-area-inset-left)"
      }
    });
  },
  {
    theme: {
      extend: {
        height: {
          "screen-safe": "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))"
        },
        maxHeight: {
          "screen-safe": "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))"
        },
        width: {
          "screen-safe": "calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right))"
        },
        maxWidth: {
          "screen-safe": "calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right))"
        }
      }
    }
  }
);
export {
  bonesPlugin,
  borderPlugin,
  focusPlugin,
  safeAreaPlugin,
  scrollbarPlugin,
  sliderPlugin,
  touchPlugin
};
