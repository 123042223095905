"use client";
import { jsx } from "react/jsx-runtime";
import { createContext, forwardRef, useContext } from "react";
const makeLinkComponent = (render) => ({ __forwardRef__: forwardRef(render) });
const DefaultLinkComponent = makeLinkComponent((props, ref) => /* @__PURE__ */ jsx("a", { ...props, ref, children: props.children }));
const LinkComponentContext = createContext(DefaultLinkComponent);
const LinkComponentProvider = LinkComponentContext.Provider;
const useLinkComponent = () => {
  const linkComponent = useContext(LinkComponentContext);
  if (linkComponent === void 0 || linkComponent.__forwardRef__ === void 0) {
    throw new Error(
      "useLinkComponent: Undefined link component - this hook must be used from within UICoreNextProvider with a link component created via the make link component helper."
    );
  }
  return linkComponent.__forwardRef__;
};
export {
  LinkComponentProvider,
  makeLinkComponent,
  useLinkComponent
};
