import { jsx, jsxs } from "react/jsx-runtime";
import { useListBox } from "@react-aria/listbox";
import { ListBoxOption } from "./ListBoxOption";
const ListBox = ({ state, emptyState, listBoxRef, ...props }) => {
  const { listBoxProps } = useListBox(props, state, listBoxRef);
  return /* @__PURE__ */ jsxs(
    "ul",
    {
      ...listBoxProps,
      ref: listBoxRef,
      className: "scrollbar border m-0 max-h-[400px] min-w-60 list-none overflow-y-auto overflow-x-hidden rounded-x0_5 \nborder-[--s-field-input-popover-color-border] bg-[--s-field-input-popover-color-bg] p-0 text-solid shadow-md",
      children: [
        state.collection.size === 0 && emptyState,
        [...state.collection].map((item) => /* @__PURE__ */ jsx(ListBoxOption, { item, state }, item.key))
      ]
    }
  );
};
export {
  ListBox
};
