import { jsx } from "react/jsx-runtime";
import { tv } from "../utils/tailwind";
const heading = tv({
  base: "m-0 text-balance",
  variants: {
    size: {
      uber: "text-uber",
      mass: "text-mass",
      hero: "text-hero",
      mega: "text-mega",
      xLarge: "text-xl",
      large: "text-lg",
      base: "text-base",
      small: "text-sm",
      xSmall: "text-xs",
      tiny: "text-tiny"
    },
    weight: {
      normal: "font-normal",
      strong: "font-medium",
      condensed: "font-condensed"
    }
  },
  defaultVariants: { size: "base", weight: "strong" }
});
const Heading = ({ as, size, weight, className, children }) => {
  const Component = as || "h1";
  return /* @__PURE__ */ jsx(Component, { className: heading({ size, weight, className }), children });
};
export {
  Heading,
  heading
};
